import { Icon } from "@combateafraude/react";
import { Upload } from "antd";
import { useContext, useEffect, useState } from "react";
import { CardText, CardTitle } from "../../../components/Card";
import { OnboardingDataContext } from "../../../context/OnboardingData";
import useAPI from "../../../hooks/useAPI";

const { Dragger } = Upload;

const ProofOfResidence = ({ isVisible, setCanGoForward }) => {
  const { getSignedFileUrl, isFetching } = useAPI();
  const { proofOfResidenceUrl, setProofOfResidenceUrl } = useContext(
    OnboardingDataContext
  );

  const [file, setFile] = useState(null);
  const [draggerFileList, setDraggerFileList] = useState([]);
  const [fileError, setFileError] = useState("");

  // Allows to move forward after succesfully sending a proof of residence
  useEffect(() => {
    if (isVisible) {
      setCanGoForward(!!proofOfResidenceUrl);
    }
  }, [isVisible, proofOfResidenceUrl]);

  useEffect(async () => {
    if (file) {
      // 15MB
      if (file.size > 15000000) {
        setFileError(
          "Tamanho máximo atingido. Por favor, selecione um arquivo menor que 15MB."
        );
        setProofOfResidenceUrl(null);
        setDraggerFileList([]);
      } else if (
        !file.type.includes("pdf") &&
        !file.type.includes("jpeg") &&
        !file.type.includes("png")
      ) {
        setFileError(
          "Tipo do arquivo inválido. Por favor, selecione um arquivo do tipo PDF, JPG ou PNG."
        );
        setProofOfResidenceUrl(null);
        setDraggerFileList([]);
      } else {
        setFileError("");
        setProofOfResidenceUrl(null);
        setDraggerFileList([file]);
        let signedUrl = await getSignedFileUrl(file);
        setProofOfResidenceUrl(signedUrl?.signedUrl?.getUrl);
      }
    } else {
      setProofOfResidenceUrl(null);
      setDraggerFileList([]);
    }
  }, [file]);

  useEffect(() => {
    if (draggerFileList.length > 0) {
      if (isFetching) {
        setDraggerFileList([{ name: file.name, status: "uploading" }]);
      } else if (proofOfResidenceUrl) {
        setDraggerFileList([{ name: file.name, status: "success" }]);
      } else {
        setDraggerFileList([{ name: file.name, status: "error" }]);
      }
    }
  }, [isFetching, proofOfResidenceUrl]);

  return (
    <div className="flex flex-col">
      <CardTitle text="Comprovante de Residência" />
      <CardText
        className="!mb-6"
        text="Anexe uma foto de seu comprovante de residência."
      />
      <Dragger
        disabled={isFetching}
        name="comprovante_de_residencia"
        onRemove={(file) => {
          setFile(null);
          return null;
        }}
        beforeUpload={(file) => {
          setFile(file);
          return false;
        }}
        fileList={draggerFileList}
      >
        <span
          className="flex justify-center items-center rounded-full bg-green-500 w-16 h-16"
          style={{ margin: "0 auto" }}
        >
          <Icon
            icon={
              isFetching
                ? "cloud"
                : proofOfResidenceUrl
                ? "checkmark"
                : "upload"
            }
            size="lg"
            white
          />
        </span>
        <p className="font-bold text-gray-800 pt-4">
          {isFetching ? (
            <>Enviando arquivo...</>
          ) : proofOfResidenceUrl ? (
            <>Arquivo salvo com sucesso!</>
          ) : (
            <>
              Arraste seu arquivo aqui, ou{" "}
              <span className="text-green-500">selecione</span>
            </>
          )}
        </p>
        <p className="text-xs pt-2">
          Arquivo PDF ou imagem (PNG ou JPG) de até 15MB
        </p>
      </Dragger>
      <CardText
        className={`overflow-y-hidden transition-all ${
          fileError ? "max-h-48 mt-4" : "max-h-0 !m-0"
        }`}
        small
        text={fileError}
      />
    </div>
  );
};

export default ProofOfResidence;

import { APIProvider } from "../../context/API";
import { LoaderProvider } from "../../context/Loader";
import { ModalsProvider } from "../../context/Modals";
import { OnboardingDataProvider } from "../../context/OnboardingData";
import { SDKProvider } from "../../context/SDK";

export const OnboardingProviders = ({ children }) => (
  <LoaderProvider>
    <ModalsProvider>
      <APIProvider>
        <SDKProvider>
          <OnboardingDataProvider>{children}</OnboardingDataProvider>
        </SDKProvider>
      </APIProvider>
    </ModalsProvider>
  </LoaderProvider>
);

import { CAFLoader } from "@combateafraude/react";
import { createContext, useState } from "react";

export const LoaderContext = createContext({
  isLoading: false,
  // text: "",
});

export const LoaderProvider = ({ children }) => {
  const [isHidden, setIsHidden] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  // const [text, setText] = useState("");

  const start = () => {
    // setText(text);
    setIsLoading(true);
    setIsHidden(false);
  };

  const stop = () => {
    setIsHidden(true);
    setTimeout(() => setIsLoading(false), 150);
  };

  const Loader = () =>
    isLoading ? (
      <div
        key="LOADER"
        className={`absolute top-0 left-0 h-full w-full z-30 transition-all ${
          isHidden ? "opacity-0" : "opacity-100"
        }`}
      >
        <span className="absolute block h-full w-full bg-white opacity-90" />
        <div className="absolute h-full w-full flex flex-col items-center justify-center">
          <CAFLoader />
          {/* <CardText text={text} /> */}
        </div>
      </div>
    ) : (
      <></>
    );

  return (
    <LoaderContext.Provider
      value={{
        start,
        stop,
        isLoading,
        Loader,
      }}
    >
      {children}
    </LoaderContext.Provider>
  );
};

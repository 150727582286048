import { useContext, useEffect, useState } from "react";
import { CardText } from "../../../components/Card";
import { OnboardingDataContext } from "../../../context/OnboardingData";
import useAPI from "../../../hooks/useAPI";
import useLoader from "../../../hooks/useLoader";
import usePFL from "../../../hooks/usePFL";

const PFL = ({ currentStep, goTo, isVisible, setCanGoForward }) => {
  const { setPFLCaptureUrl } = useContext(OnboardingDataContext);

  const {
    open: PFLOpen,
    close: PFLClose,
    startCapture: PFLStartCapture,
    PFLIsOpen,
    openingError: PFLOpeningError,
    PFLPreviewImg,
  } = usePFL();
  const [startLoader, stopLoader] = useLoader();
  const { uploadImage } = useAPI();

  const [containerNode, setContainerNode] = useState(null);
  const [previousPreviewImg, setPreviewPreviewImg] = useState(null);

  useEffect(() => {
    if (isVisible) {
      PFLOpen(containerNode);
    }
  }, [isVisible]);

  // Only allows to move forward when there is a preview image
  useEffect(() => {
    if (isVisible) {
      setCanGoForward(!!PFLPreviewImg);
    }
  }, [isVisible, PFLPreviewImg]);

  useEffect(() => {
    if (isVisible) {
      if (!PFLIsOpen && !PFLOpeningError) startLoader();
      else stopLoader();
    }
  }, [isVisible, PFLIsOpen, PFLOpeningError]);

  useEffect(() => {
    if (
      !isVisible &&
      PFLIsOpen &&
      currentStep.name !== "PFL" &&
      currentStep.name !== "PFL_PREVIEW"
    ) {
      PFLClose();
    }
  }, [isVisible, PFLIsOpen, containerNode, currentStep]);

  useEffect(() => {
    if (isVisible && containerNode) {
      PFLStartCapture(containerNode);
    }
  }, [isVisible, containerNode]);

  // Goes to the preview automatically when there is a new one
  useEffect(() => {
    if (PFLPreviewImg && PFLPreviewImg !== previousPreviewImg) {
      uploadSelfie();
      setPreviewPreviewImg(PFLPreviewImg);
      goTo("PFL_PREVIEW");
    }
  }, [PFLPreviewImg, previousPreviewImg]);

  const uploadSelfie = async () => {
    uploadImage(PFLPreviewImg, "PFLCapture.jpg")
      .then((result) => {
        if (result && result.getUrl) {
          setPFLCaptureUrl(result.getUrl);
        }
      })
      .catch((err) => console.log("PFL UPLOAD ERROR: ", err));
  };

  return (
    <div
      ref={(node) => setContainerNode(node)}
      className="h-full w-full flex items-center justify-center"
    >
      {PFLOpeningError && (
        <CardText
          className="mx-10"
          text="Ocorreu um erro ao abrir o Detector de Faces. Por favor, refaça o Onboarding. Caso o problema persistir, entre em contato com o suporte."
        />
      )}
    </div>
  );
};

export default PFL;

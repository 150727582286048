import { useContext, useEffect, useState } from "react";
import { APIContext } from "../context/API";
import { SDKContext } from "../context/SDK";

// Importando desse jeito estava impedindo a compilação do projeto, verificar quando possível
// import * as DocumentDetector from "../assets/js/caf-sdks/document-detector.min.js";

const useDD = () => {
  const {
    DD,
    setDD,
    DDIsOpen,
    setDDIsOpen,
    DDFrontPreviewImg,
    setDDFrontPreviewImg,
    DDBackPreviewImg,
    setDDBackPreviewImg,
    DDCurrentDocumentSide,
    setDDCurrentDocumentSide,
    DDDocumentType,
    setDDDocumentType,
  } = useContext(SDKContext);
  const { clientOptions } = useContext(APIContext);

  const [isOpening, setIsOpening] = useState(false);
  const [openingError, setOpeningError] = useState(false);

  useEffect(() => {
    if (
      DDDocumentType &&
      !clientOptions?.documentTypes?.includes(DDDocumentType)
    ) {
      setDDDocumentType("");
    }
  }, [clientOptions, DDDocumentType, setDDDocumentType]);

  useEffect(() => {
    close();
  }, [DDDocumentType]);

  const initializeDD = () => {
    if (DD) return;

    const options = {
      client: "onboarding",
      hideTitle: false,
      hideCloseButton: true,
      preview: true,
      // trackingId: this.analyticsTrackingId,
    };

    try {
      const sdk = new window.DocumentDetector.initialize(
        process.env.REACT_APP_COMBATEAFRAUDE_MOBILE_API_TOKEN,
        options
      );

      sdk.on("ready", async (e) => setDD(sdk));
    } catch (err) {
      console.log("Erro ao inicializar SDK DocumentDetector: ", err);
    }
  };

  useEffect(() => {
    initializeDD();
  }, []);

  let openAttempts = 0;

  const open = (containerNode) => {
    if (!DDDocumentType) {
      console.log("Tipo de documento não selecionado.");
    } else if (DDIsOpen) {
      console.log("O DD já está aberto.");
    } else if (!isOpening) {
      setIsOpening(true);
      setOpeningError(false);
      DD.open({
        documentType: DDDocumentType,
        parentContainer: containerNode,
      })
        .then(() => {
          if (DDCurrentDocumentSide === "front") DD.startFrontCapture();
          else if (DDCurrentDocumentSide === "back") DD.startBackCapture();
          openAttempts = 0;
          setDDIsOpen(true);
          setIsOpening(false);
          setOpeningError(false);
        })
        .catch((err) => {
          console.log("DD OPEN ERROR: ", err);
          if (openAttempts >= 2) {
            console.log(
              "O DD já tentou abrir 3 vezes sem sucesso, abortando tentativas."
            );
            setDDIsOpen(false);
            setIsOpening(false);
            setOpeningError(true);
            openAttempts = 0;
          } else {
            openAttempts++;
            console.log("Tentando abrir o DD novamente...");
            setTimeout(() => open(containerNode), 1000);
          }
        });

      DD.on("front_capture_finish", (data) => {
        setDDFrontPreviewImg(data?.detail?.imagePath);
      });

      DD.on("back_capture_finish", (data) => {
        setDDBackPreviewImg(data?.detail?.imagePath);
      });

      DD.on("failed", (data) => {
        console.log("DD INITIALIZE ERROR: ", data.detail);
        setDDIsOpen(false);
      });

      DD.on("finished", (e) => {
        setDDIsOpen(false);
        // const front = e.detail.captureFront.imagePath;
        // const back = e?.detail?.captureBack?.imagePath;
        // setDDBackPreviewImg(back);
      });

      DD.on("closed", (e) => {
        setDDIsOpen(false);
      });
    }
  };

  const close = () => {
    if (DDIsOpen) {
      DD.close();
      setDDIsOpen(false);
    }
  };

  const startFrontCapture = (containerNode) => {
    if (!DDIsOpen) {
      open(containerNode);
    } else if (!isOpening) {
      console.log("DD STARTING FRONT CAPTURE");
      DD.startFrontCapture();
    }
  };

  const startBackCapture = (containerNode) => {
    if (!DDIsOpen) {
      open(containerNode);
    } else if (!isOpening) {
      console.log("DD STARTING FRONT CAPTURE");
      DD.startBackCapture();
    }
  };

  return {
    DD,
    open,
    close,
    DDIsOpen,
    isOpening,
    openingError,
    startFrontCapture,
    startBackCapture,
    DDDocumentType,
    setDDDocumentType,
    DDFrontPreviewImg,
    DDBackPreviewImg,
    DDCurrentDocumentSide,
    setDDCurrentDocumentSide,
  };
};

export default useDD;

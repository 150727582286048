import { useEffect } from "react";
import { CardText, CardTitle } from "../../../components/Card";
import usePFL from "../../../hooks/usePFL";

const PFLPreview = ({ goBackward, isVisible, setCanGoForward }) => {
  const { PFLPreviewImg } = usePFL();

  // Only allows to move forward when there is a preview image
  useEffect(() => {
    if (isVisible) {
      setCanGoForward(!!PFLPreviewImg);
    }
  }, [isVisible, setCanGoForward, PFLPreviewImg]);

  return (
    <div className="flex flex-col items-center text-center">
      <img
        className="h-56 mb-6 rounded-lg"
        src={PFLPreviewImg}
        alt="Foto do seu rosto"
      />
      <CardTitle small text="A foto ficou boa?" />
      <CardText
        small
        className="!mb-5"
        text="Verifique se a face está nítida, sem óculos, boné ou reflexos."
      />
      <a
        className="underline text-blue-400 text-base"
        href="#"
        onClick={goBackward}
      >
        Tirar novamente
      </a>
    </div>
  );
};

export default PFLPreview;

import { useEffect, useState } from "react";
import {
  CardIllustration,
  CardText,
  CardTitle,
} from "../../../components/Card";
import { Icon } from "@combateafraude/react";
import { Button } from "../../../components/Button";

import CameraAccessIllustration from "../../../assets/images/illustrations/camera-access.png";
import useDD from "../../../hooks/useDD";
// import usePFL from "../../../hooks/usePFL";

const CameraAccess = ({ isVisible, setCanGoForward }) => {
  const [permissionsGranted, setPermissionsGranted] = useState(false);
  const [permissionsRefused, setPermissionsRefused] = useState(false);
  const [gettingPermissions, setGettingPermissions] = useState(false);
  const { DD } = useDD();
  // const { PFL } = usePFL();

  const getPermissions = () => {
    setGettingPermissions(true);
    DD.initPermissions().then((permission) => {
      if (permission) {
        setPermissionsGranted(true);
      } else {
        setPermissionsRefused(true);
      }
      setGettingPermissions(false);
    });
    // Promise.all([DD.initPermissions(), PFL.initPermissions()]).then(
    //   ([permissionDD, permisisonPFL]) => {
    //     if (permissionDD && permisisonPFL) {
    //       setPermissionsGranted(true);
    //     } else {
    //       setPermissionsRefused(true);
    //     }
    //     setGettingPermissions(false);
    //   }
    // );
  };

  // Only allows to move forward after giving camera permisisons
  useEffect(() => {
    if (isVisible) {
      setCanGoForward(permissionsGranted);
    }
  }, [isVisible, permissionsGranted, setCanGoForward]);

  return (
    <div className="flex flex-col items-center h-full justify-center text-center">
      <CardIllustration
        src={CameraAccessIllustration}
        alt="Permita acesso à câmera"
      />
      <CardTitle text="Acesso à câmera" />
      <CardText
        className="!mb-6"
        text="Pra continuar é necessário permitir
acesso à sua câmera."
      />
      <Button
        type={permissionsRefused ? "danger" : "primary"}
        loading={gettingPermissions}
        outlined={permissionsGranted || permissionsRefused}
        falseDisabled={permissionsGranted || permissionsRefused}
        icon={
          permissionsGranted ? (
            <Icon icon="check_d" />
          ) : permissionsRefused ? (
            <Icon icon="alert-triangle" />
          ) : (
            ""
          )
        }
        onClick={() => getPermissions()}
      >
        {permissionsGranted
          ? "Acesso concedido"
          : permissionsRefused
          ? "Acesso negado"
          : "Habilitar acesso"}
      </Button>
      {permissionsRefused && (
        <CardText
          className="mt-4"
          small
          text="Por favor, acesse as configurações do seu navegador para permitir o acesso à câmera para este site."
        />
      )}
    </div>
  );
};

export default CameraAccess;

import { createContext, useState } from "react";

export const ModalsContext = createContext({
  openModals: [],
});

export const ModalsProvider = ({ children }) => {
  const [openModals, setOpenModals] = useState([]);

  return (
    <ModalsContext.Provider
      value={{
        openModals,
        setOpenModals,
      }}
    >
      {children}
    </ModalsContext.Provider>
  );
};

import {
  CardIllustration,
  CardText,
  CardTitle,
} from "../../../components/Card";

import ProfileApprovedIllustration from "../../../assets/images/illustrations/profile-approved.png";

const ProfileApproved = () => {
  return (
    <div className="flex flex-col items-center h-full justify-center text-center">
      <CardIllustration src={ProfileApprovedIllustration} alt="Sucesso" />
      <CardTitle text="Perfil aprovado" />
      <CardText text="Tudo certo, já validamos seu perfil." />
    </div>
  );
};

export default ProfileApproved;

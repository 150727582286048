import { useContext, useEffect, useState } from "react";
import { Icon } from "@combateafraude/react";
import { CardLayout } from "../components/Card";
import { ModalsContext } from "../context/Modals";
import StepLayout from "../pages/Onboarding/StepLayout";
import { Button } from "../components/Button";

const useModal = () => {
  const { openModals, setOpenModals } = useContext(ModalsContext);

  const Modal = ({ customKey, children }) => {
    // Used to show and hide the modal with a fade
    const [isHidden, setIsHidden] = useState(true);

    useEffect(() => {
      setTimeout(() => setIsHidden(false), 1);
    }, []);

    const close = () => {
      setIsHidden(true);
      setTimeout(
        () =>
          setOpenModals(
            openModals.filter((openModal) => openModal.key === customKey)
          ),
        [150]
      );
    };

    return (
      <CardLayout
        key={customKey + "_MODAL"}
        className={`!absolute top-0 left-0 z-40 transition-all transform ${
          isHidden ? "opacity-0 scale-75" : "opacity-100 scale-100"
        } `}
      >
        <div className="relative flex-grow overflow-y-auto overflow-x-hidden -mx-6 -mt-6">
          <StepLayout>{children}</StepLayout>
        </div>
        <div className={`flex pt-4`}>
          <Button
            rounded
            light
            className="!m-0"
            type="primary"
            onClick={close}
            shape="circle"
            icon={<Icon icon="close" />}
          />
        </div>
      </CardLayout>
    );
  };

  const openModal = (key, modalBody) => {
    setOpenModals([
      ...openModals,
      {
        key,
        component: (
          <Modal key={key} customKey={key}>
            {modalBody}
          </Modal>
        ),
      },
    ]);
  };

  return { openModal };
};

export default useModal;

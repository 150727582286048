import { useEffect, useState } from "react";
import PhoneIllustration from "../assets/images/illustrations/phone.png";
// import QRCodeImage from "../assets/images/qrcode.png";
import LeftArrowImage from "../assets/images/left-arrow.png";

const QRCode = require("qrcode.react");
const TinyURL = require("tinyurl");
const currentUrl = window.location.href;

export const OnlyMobile = () => {
  const [shortUrl, setShortUrl] = useState("Carregando endereço...");

  useEffect(() => {
    TinyURL.shorten(currentUrl, function (res, err) {
      if (err || !res) {
        console.log("TINY URL ERROR: ", err, "CURRENT URL: ", currentUrl);
        setShortUrl(currentUrl.replace("https://", ""));
      } else setShortUrl(res.replace("https://", ""));
    });
  }, []);

  return (
    <div className="relative h-full w-screen p-20 xl:p-32 max-w-5xl">
      <div className="flex flex-col relative z-20">
        <h1 className="text-6xl font-bold text-gray-800">
          Este link só pode ser acessado de seu celular
        </h1>
        <p className="text-3xl mb-16 text-gray-800">
          Para prosseguir é necessário acessar esta página em seu dispositivo
          móvel.
        </p>
        <div className="flex">
          <QRCode value={currentUrl} size={300} />
          {/* <img src={QRCodeImage} alt="QRCode que direciona ao celular" /> */}
          <div className="flex flex-col items-start pl-10">
            <img
              className="h-20 object-scale-down"
              src={LeftArrowImage}
              alt=""
            />
            <p className="text-3xl font-bold text-gray-800 mb-2">
              Aponte sua câmera aqui
            </p>
            <p className="text-2xl text-gray-800">
              Este QR Code vai lhe redirecionar para esta página
              automaticamente.
            </p>
          </div>
        </div>
        <p className="text-2xl text-gray-800 pt-10">
          Ou abra seu navegador e acesse:
          <br />
          <span className="font-bold">{shortUrl}</span>
        </p>
      </div>
      <img
        className="fixed right-0 top-0 h-full invisible xl:visible z-10"
        src={PhoneIllustration}
        alt="Telefone com a câmera ligada"
      />
    </div>
  );
};

import { Button as CafButton } from "@combateafraude/react";
import classNames from "classnames";

export const Button = ({
  children,
  className,
  type,
  rounded,
  size,
  shape,
  disabled,
  outlined,
  onClick,
  // custom
  light,
  falseDisabled,
  ...rest
}) => (
  <CafButton
    className={classNames(
      "font-bold text-base !border-none !shadow-none",
      className,
      {
        "min-h-9": size !== "small",
        "min-w-9": shape === "circle",
        "px-5 py-0": !rounded && size !== "small",
        "!text-primary": type === "primary" && (outlined || light),
        "!text-gray-500": type === "default" && (outlined || light),
        "!text-red-500": type === "danger" && (outlined || light),
        "!border-2 !border-primary": type === "primary" && outlined,
        "!border-2 !border-gray-500": type === "default" && outlined,
        "!border-2 !border-red-500": type === "danger" && outlined,
        "!bg-primary": type === "primary" && !light && !disabled,
        "!bg-primary-light": type === "primary" && light && !disabled,
        "!bg-gray-100 !text-gray-300": light && (disabled || falseDisabled),
        "!bg-gray-300": !light && disabled,
        "pointer-events-none cursor-not-allowed": falseDisabled,
      }
    )}
    type={type}
    rounded={rounded}
    size={size}
    shape={shape}
    disabled={disabled}
    outlined={outlined}
    onClick={falseDisabled ? null : onClick}
    {...rest}
  >
    {children}
  </CafButton>
);

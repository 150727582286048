import { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DevOptions from "../components/DevOptions";
import useLoader from "../hooks/useLoader";

const defaultClientOptions = {
  background: "loading",
  colors: { primary: "#666", secondary: "#666", illustrations: "#666" },
  font: "Roboto",
  documentTypes: [],
  domain: "",
  logo: "loading",
  favicon: "",
  mobileOnly: false,
  steps: [],
  footer: { background: "", logo: "", text: "" },
};

export const APIContext = createContext({});

export const APIProvider = ({ children }) => {
  const [startLoader, stopLoader] = useLoader();

  const [clientOptions, setClientOptions] = useState(defaultClientOptions);
  const [hasFetched, setHasFetched] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [onboardingStatus, setOnboardingStatus] = useState(undefined);

  const [finalPostSuccess, setFinalPostSuccess] = useState(false);
  const [finalPostError, setFinalPostError] = useState(false);

  let { token } = useParams();

  useEffect(() => {
    if (token) {
      startLoader();
      setIsFetching(true);
      fetch(process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API + token)
        .then((res) => res.json())
        .then(
          (result) => {
            console.log("FETCH RESULT: ", result);
            if (result.message) {
              setIsTokenValid(false);
            } else {
              setIsTokenValid(true);
              setClientOptions(result.template);
              setOnboardingStatus(result.data?.status);
            }
            setIsFetching(false);
            setHasFetched(true);
            stopLoader();
          },
          (error) => {
            console.log("FETCH ERROR: ", error);
            setIsFetching(false);
            setHasFetched(true);
            stopLoader();
          }
        );
    } else {
      setIsTokenValid(false);
      setHasFetched(true);
    }
  }, [token]);

  const sendFinalPost = (data) => {
    startLoader();
    setFinalPostError(false);
    setFinalPostSuccess(false);
    setIsFetching(true);
    fetch(process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API + token, {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("FINAL POST RESULT: ", result);
          if (result.message) {
            setFinalPostError(true);
          } else {
            setFinalPostSuccess(true);
          }
          setIsFetching(false);
          stopLoader();
        },
        (error) => {
          console.log("FINAL POST ERROR: ", error);
          setFinalPostError(true);
          setIsFetching(false);
          stopLoader();
        }
      );
  };

  return (
    <APIContext.Provider
      value={{
        clientOptions,
        hasFetched,
        isFetching,
        isTokenValid,
        onboardingStatus,
        sendFinalPost,
        finalPostSuccess,
        finalPostError,
      }}
    >
      {process.env.REACT_APP_ENV === "dev" && (
        <DevOptions
          clientOptions={clientOptions}
          setClientOptions={setClientOptions}
        />
      )}
      {children}
    </APIContext.Provider>
  );
};

import { Checkbox } from "@combateafraude/react";
import { useContext, useEffect, useState } from "react";
import { CardText, CardTitle } from "../../../components/Card";
import { OptionButton } from "../../../components/OptionButton";
import { APIContext } from "../../../context/API";
import useDD from "../../../hooks/useDD";
import useModal from "../../../hooks/useModal";
import { UseTerms } from "../common";

const DocumentType = ({ goTo, isVisible, setCanGoForward }) => {
  const { DDDocumentType, setDDDocumentType } = useDD();
  const { openModal } = useModal();
  const { clientOptions } = useContext(APIContext);

  const [termsAccepted, setTermsAccepted] = useState(false);

  // Only allows to move forward with a document type selected
  useEffect(() => {
    if (isVisible) {
      setCanGoForward(!!DDDocumentType && termsAccepted);
    }
  }, [isVisible, DDDocumentType, setCanGoForward, termsAccepted]);

  const openUseTerms = (event) => {
    event.preventDefault();
    openModal("USE_TERMS", <UseTerms />);
  };

  return (
    <div className="flex flex-col">
      <CardTitle text="Qual seu documento?" />
      <CardText
        className="!mb-6"
        text="Você precisa tê-lo em mãos para prosseguir com o cadastro."
      />
      <div className="flex flex-col mb-4">
        {(!clientOptions?.documentTypes ||
          (!clientOptions?.documentTypes?.includes("RG") &&
            !clientOptions?.documentTypes?.includes("CNH"))) && (
          <CardText
            small
            className="!mb-0"
            text="Nenhum tipo de documento disponível."
          />
        )}

        {clientOptions?.documentTypes?.includes("RG") && (
          <OptionButton
            icon="person"
            title="RG"
            description="Documento de identidade"
            onClick={() => setDDDocumentType("RG")}
            disabled={!clientOptions?.documentTypes?.includes("RG")}
            selected={DDDocumentType === "RG"}
            className="!mb-3"
          />
        )}
        {clientOptions?.documentTypes?.includes("CNH") && (
          <OptionButton
            icon="car"
            title="CNH"
            description="Carteira nacional de habilitação"
            onClick={() => setDDDocumentType("CNH")}
            disabled={!clientOptions?.documentTypes?.includes("CNH")}
            selected={DDDocumentType === "CNH"}
            className="!mb-3"
          />
        )}
      </div>
      <Checkbox
        checked={termsAccepted}
        onChange={(e) => setTermsAccepted(e.target.checked)}
      >
        Sim, quero verificar minha identidade. Eu li e aceito os{" "}
        <a className="underline" href="#" onClick={openUseTerms}>
          termos de uso e políticas de privacidade
        </a>{" "}
        .
      </Checkbox>
    </div>
  );
};

export default DocumentType;

import {
  CardIllustration,
  CardText,
  CardTitle,
} from "../../../components/Card";

import DocumentCaptureIllustration from "../../../assets/images/illustrations/document-capture.png";
import { useEffect } from "react";

const Welcome = ({ isVisible, setCanGoForward }) => {
  // Allows to move forward
  useEffect(() => {
    if (isVisible) {
      setCanGoForward(true);
    }
  }, [isVisible, setCanGoForward]);

  return (
    <div className="flex flex-col items-center h-full justify-center text-center">
      <CardIllustration
        src={DocumentCaptureIllustration}
        alt="Identificação de documento e face inteligente"
      />
      <CardTitle text="Captura inteligente do documento" />
      <CardText text="Tenha em mãos o seu RG ou CNH." />
    </div>
  );
};

export default Welcome;

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const StepLayout = ({ stepIndex, currentIndex, step, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [transform, setTransform] = useState("");

  useEffect(() => {
    if (isNaN(stepIndex) || stepIndex === currentIndex) {
      setTimeout(() => {
        setIsVisible(true);
        setTransform("opacity-100 translate-x-0");
      }, 150);
    } else {
      setTimeout(() => setIsVisible(false), 150);
      if (stepIndex < currentIndex) {
        setTransform("opacity-0 -translate-x-10");
      } else if (stepIndex > currentIndex) {
        setTransform("opacity-0 translate-x-10");
      }
    }
  }, [stepIndex, currentIndex]);

  return (
    <div
      className={`absolute top-0 w-full h-full ${
        step?.noPadding ? "" : "px-7 pt-6"
      } transition-all transform ${transform} ${
        isVisible ? "visible z-10" : "invisible overflow-hidden"
      }`}
    >
      {React.cloneElement(children, { isVisible })}
    </div>
  );
};

StepLayout.propTypes = {
  stepIndex: PropTypes.number,
  currentIndex: PropTypes.number,
};

export default StepLayout;

import { useEffect, useState } from "react";
import { CardText, CardTitle } from "../../../components/Card";
import useDD from "../../../hooks/useDD";
import { isMobile } from "../../../utils";

const DDPreview = ({
  goTo,
  goBackward,
  isVisible,
  setCustomGoForward,
  setCanGoForward,
}) => {
  const {
    DDCurrentDocumentSide,
    setDDCurrentDocumentSide,
    DDFrontPreviewImg,
    DDBackPreviewImg,
  } = useDD();
  const [customGoForwardWasSet, setCustomGoForwardWasSet] = useState(false);

  // Only allows to move forward when there is a preview image
  useEffect(() => {
    if (isVisible) {
      if (DDCurrentDocumentSide === "front") {
        setCanGoForward(!!DDFrontPreviewImg);
      } else if (DDCurrentDocumentSide === "back") {
        setCanGoForward(!!DDBackPreviewImg);
      }
    }
  }, [isVisible, DDCurrentDocumentSide]);

  useEffect(() => {
    if (isVisible && DDCurrentDocumentSide === "front") {
      if (!customGoForwardWasSet) {
        setCustomGoForward(() => () => {
          setDDCurrentDocumentSide("back");
          goTo("DD");
        });
        setCustomGoForwardWasSet(true);
      }
    }
  }, [isVisible, DDCurrentDocumentSide, customGoForwardWasSet]);

  useEffect(() => {
    if (!isVisible) {
      setCustomGoForwardWasSet(false);
    }
  }, [isVisible]);

  return (
    <div className="flex flex-col items-center text-center">
      <img
        className={`h-56 mb-6 object-scale-down rounded-lg ${
          isMobile() ? "transform -rotate-90" : ""
        }`}
        src={
          DDCurrentDocumentSide === "front"
            ? DDFrontPreviewImg
            : DDCurrentDocumentSide === "back"
            ? DDBackPreviewImg
            : null
        }
        alt="Foto do seu documento"
      />
      <CardTitle small text="A foto ficou boa?" />
      <CardText
        small
        className="!mb-5"
        text="Veja se todas informações estão legíveis e os documentos sem reflexos."
      />
      <a
        className="underline text-blue-400 text-base"
        href="#"
        onClick={goBackward}
      >
        Tirar novamente
      </a>
    </div>
  );
};

export default DDPreview;

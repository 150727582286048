import { Icon } from "@combateafraude/react";
import { Progress } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import StepLayout from "./StepLayout";
import { APIContext } from "../../context/API";
import { LoaderContext } from "../../context/Loader";
import {
  Welcome,
  DocumentType,
  CameraAccess,
  Instructions,
  Done,
  DataConfirmation,
  DispatchAddress,
  ProofOfResidence,
  PFL,
  DD,
  DDPreview,
  PFLPreview,
} from "./steps";
import { NotFound, ProfileApproved, UseTerms } from "./common";
import { Button } from "../../components/Button";

export const availableSteps = [
  {
    name: "WELCOME",
    component: (props) => <Welcome key="WELCOME" {...props} />,
  },
  {
    name: "DOCUMENT_TYPE",
    component: (props) => <DocumentType key="DOCUMENT_TYPE" {...props} />,
  },
  {
    name: "CAMERA_ACCESS",
    component: (props) => <CameraAccess key="CAMERA_ACCESS" {...props} />,
  },
  {
    name: "INSTRUCTIONS",
    component: (props) => <Instructions key="INSTRUCTIONS" {...props} />,
  },
  {
    name: "DD",
    component: (props) => <DD key="DD" {...props} />,
    noPadding: true,
  },
  {
    name: "PFL",
    component: (props) => <PFL key="PFL" {...props} />,
    noPadding: true,
  },
  {
    name: "DATA_CONFIRMATION",
    component: (props) => (
      <DataConfirmation key="DATA_CONFIRMATION" {...props} />
    ),
  },
  {
    name: "DISPATCH_ADDRESS",
    component: (props) => <DispatchAddress key="DISPATCH_ADDRESS" {...props} />,
  },
  {
    name: "PROOF_OF_RESIDENCE",
    component: (props) => (
      <ProofOfResidence key="PROOF_OF_RESIDENCE" {...props} />
    ),
  },
  {
    name: "DONE",
    component: (props) => <Done key="DONE" {...props} />,
  },
];

export const commonSteps = [
  {
    name: "DD_PREVIEW",
    component: (props) => <DDPreview key="DD_PREVIEW" {...props} />,
  },
  {
    name: "PFL_PREVIEW",
    component: (props) => <PFLPreview key="PFL_PREVIEW" {...props} />,
  },
  {
    name: "NOT_FOUND",
    component: (props) => <NotFound key="NOT_FOUND" {...props} />,
  },
  {
    name: "PROFILE_APPROVED",
    component: (props) => <ProfileApproved key="PROFILE_APPROVED" {...props} />,
  },
  {
    name: "USE_TERMS",
    component: (props) => <UseTerms key="USE_TERMS" {...props} />,
    noNavigation: false,
  },
];

const Onboarding = (props) => {
  const { clientOptions } = useContext(APIContext);
  const { isLoading } = useContext(LoaderContext);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentStep, setCurrentStep] = useState(availableSteps[currentIndex]);
  const [stepPercentage, setStepPercentage] = useState(0);
  const [clientSteps, setClientSteps] = useState([]);
  const [clientNavigableSteps, setClientNavigableSteps] = useState([]);
  const [oldSteps, setOldSteps] = useState([]);

  useEffect(() => {
    if (
      clientOptions &&
      clientOptions.steps &&
      clientOptions.steps !== oldSteps
    ) {
      let newSteps = [];
      clientOptions.steps.forEach((step) => {
        let fullStep = availableSteps.find(
          (availableStep) => step.name === availableStep.name
        );
        if (!fullStep) console.log("Step não encontrado: ", step);
        else {
          newSteps.push(fullStep);
          if (fullStep.name === "DD")
            newSteps.push(commonSteps.find((s) => s.name === "DD_PREVIEW"));
          if (fullStep.name === "PFL")
            newSteps.push(commonSteps.find((s) => s.name === "PFL_PREVIEW"));
        }
      });
      setClientSteps(newSteps);
      setClientNavigableSteps(newSteps.filter((step) => !step.noNavigation));
      setOldSteps(clientOptions.steps);
    }
  }, [clientOptions, oldSteps]);

  /// Reset every time the index changes
  // Customize what will be executed whem clicking the forward navigation button
  const [customGoForward, setCustomGoForward] = useState();
  // Customize what will be executed whem clicking the backward navigation button
  const [customGoBackward, setCustomGoBackward] = useState();
  // Disables the forward navigation button when there are still required actions to be made
  const [canGoForward, setCanGoForward] = useState(true);
  // Hide the navigation bar for the current step
  const [hideNavigationBar, setHideNavigationBar] = useState(false);

  useEffect(() => {
    if (currentIndex >= 0 && currentIndex < clientNavigableSteps.length) {
      setCurrentStep(clientNavigableSteps[currentIndex]);
      setStepPercentage(
        (100 / clientNavigableSteps.length) * (currentIndex + 1)
      );
    }
    setCustomGoForward(null);
    setCustomGoBackward(null);
    setCanGoForward(true);
    setHideNavigationBar(false);
  }, [currentIndex, clientSteps]);

  /** Goes to the next step */
  const goForward = useCallback(() => {
    if (!isLoading) {
      if (customGoForward) customGoForward();
      else if (canGoForward && currentIndex < clientSteps.length - 1)
        setCurrentIndex(currentIndex + 1);
    }
  }, [isLoading, canGoForward, currentIndex, clientSteps, customGoForward]);

  /** Goes to the previous step */
  const goBackward = useCallback(() => {
    if (!isLoading) {
      if (customGoBackward) customGoBackward();
      else if (currentIndex > 0) setCurrentIndex(currentIndex - 1);
    }
  }, [isLoading, canGoForward, currentIndex, clientSteps, customGoBackward]);

  /** Goes to the specific step */
  const goTo = (stepName) => {
    let stepIndex = clientSteps.findIndex((step) => step.name === stepName);
    if (!isNaN(stepIndex)) setCurrentIndex(stepIndex);
  };

  const [shouldGoForward, setShouldGoForward] = useState(false);

  /** Enter key navigation */
  useEffect(() => {
    const keyboardNavigation = (event) => {
      if (event.key === "Enter") setShouldGoForward(true);
    };

    document.addEventListener("keydown", keyboardNavigation);
    return () => {
      if (document.removeEventListener) {
        // For all major browsers, except IE 8 and earlier
        document.removeEventListener("keydown", keyboardNavigation);
      } else if (document.detachEvent) {
        // For IE 8 and earlier versions
        document.detachEvent("keydown", keyboardNavigation);
      }
    };
  }, []);

  useEffect(() => {
    if (shouldGoForward) {
      goForward();
      setTimeout(() => setShouldGoForward(false), 200);
    }
  }, [shouldGoForward]);

  return (
    <>
      <div className="relative flex-grow overflow-y-auto overflow-x-hidden -mx-6 -mt-6">
        {clientSteps.map((step, i) => (
          <StepLayout
            key={step.name + "_LAYOUT"}
            stepIndex={i}
            currentIndex={currentIndex}
            step={step}
          >
            {step.component({
              currentStep,
              goForward,
              goBackward,
              goTo,
              setCustomGoForward,
              setCustomGoBackward,
              setCanGoForward,
              setHideNavigationBar,
            })}
          </StepLayout>
        ))}
      </div>
      <div
        className={`flex content-between items-center pt-4 transition-all ${
          hideNavigationBar ? "opacity-0 invisible" : ""
        }`}
      >
        <Button
          rounded
          light
          className="!m-0"
          type="primary"
          onClick={goBackward}
          shape="circle"
          icon={<Icon icon="arrow_left" />}
          falseDisabled={currentIndex <= 0}
        />
        <Progress
          className="!mx-4 !my-0"
          percent={stepPercentage}
          strokeColor="var(--color-primary)"
          showInfo={false}
        />
        <Button
          className="!m-0"
          type="primary"
          onClick={goForward}
          disabled={!canGoForward || currentIndex >= clientSteps.length - 1}
        >
          Continuar
        </Button>
      </div>
      {/* Navigation buttons (only dev) */}
      {process.env.REACT_APP_ENV === "dev" && (
        <div className="absolute left-0 bottom-0 w-full flex items-center justify-center gap-2 opacity-50 transform scale-75">
          {clientSteps.map((step, i) => (
            <Button
              rounded
              size="small"
              className="!m-0"
              type={currentIndex === i ? "primary" : "secondary"}
              outlined={false}
              onClick={() => goTo(step.name)}
              shape="circle"
            >
              {i}
            </Button>
          ))}
        </div>
      )}
    </>
  );
};

export default Onboarding;

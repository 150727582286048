import { useEffect } from "react";
import { CardText, CardTitle } from "../../../components/Card";

const UseTerms = ({ isVisible, setCanGoForward }) => {
  // Never allows to move forward
  useEffect(() => {
    if (isVisible) {
      setCanGoForward && setCanGoForward(false);
    }
  }, [isVisible, setCanGoForward]);

  return (
    <div className="flex flex-col">
      <CardTitle text="Termos de Uso" />
      <CardText
        className="!mb-5"
        text="Última modificação em 28/09/2020 às 15:55."
      />
      <CardText
        small
        text={`‍COMBATEAFRAUDE TECNOLOGIA DA INFORMAÇÃO LTDA., sociedade empresária limitada, devidamente constituída e existente de acordo as leis do Brasil, inscrita no CNPJ sob o no34.102.645/0001-57, com sede à Avenida Treze de Maio 41, 9° andar - Centro - Rio de Janeiro -RJ, CEP: 20031-007, representada segundo os termos de seus documentos constitutivos.

A COMBATE A FRAUDE oferece, por meio do website (“Plataforma”), um Software que permite a realização de serviços destinados a análise de dados para combater irregularidades e fraudes.
Estes Termos e Condições de Uso (“Termo”) estabelecem os termos e condições aplicáveis à utilização do Software e da Plataforma. Ao aderir a Proposta Comercial, e/ou utilizar oSoftware e/ou acessar a Plataforma, o USUÁRIO assume e expressamente concorda comeste Termo.
A proposta comercial referida acima, e no corpo desse “termo”, está presente no pre âmbulo do contrato de prestação de serviços firmados entre a COMBATE A FRAUDE e a CONTRATANTE.

A COMBATE A FRAUDE oferece, por meio do website (“Plataforma”), um Software que permite a realização de serviços destinados a análise de dados para combater`}
      />
    </div>
  );
};

export default UseTerms;

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { OnboardingProviders } from "./pages/Onboarding/OnboardingProviders";

import "./styles/main.less";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path={["/:token", "/"]}>
          <OnboardingProviders>
            <App />
          </OnboardingProviders>
        </Route>
        <Route
          path="/:token/*"
          render={(props) => <Redirect to={`/${props.match.params.token}`} />}
        />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

/**
 * Checks if the browser is running on mobile.
 */
export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
    navigator.userAgent
  );
}

/**
 * Brightens or darkens a color.
 * https://stackoverflow.com/a/21038522/9629238
 */
export function shadeColor(col, amount) {
  function hex2(c) {
    c = Math.round(c);
    if (c < 0) c = 0;
    if (c > 255) c = 255;

    var s = c.toString(16);
    if (s.length < 2) s = "0" + s;

    return s;
  }

  function color(r, g, b) {
    return "#" + hex2(r) + hex2(g) + hex2(b);
  }

  var r = parseInt(col.substr(1, 2), 16);
  var g = parseInt(col.substr(3, 2), 16);
  var b = parseInt(col.substr(5, 2), 16);

  if (amount < 0) {
    r = (1 + amount) * r;
    g = (1 + amount) * g;
    b = (1 + amount) * b;
  } else {
    r = (1 - amount) * r + amount * 255;
    g = (1 - amount) * g + amount * 255;
    b = (1 - amount) * b + amount * 255;
  }

  return color(r, g, b);
}

export const dateFormat = "DD/MM/YYYY";

/// REGEX
export const cpfRegex = /[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}/g;
export const cepRegex = /[0-9]{2}\.?[0-9]{3}-?[0-9]{3}/g;
/** Any alphabetic character and spaces */
export const alphabeticRegex = /^(\p{L}+\s?)+$/u;
/** Color in hex */
export const hexRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;

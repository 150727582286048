import { useEffect, useState } from "react";

const useFontLoader = () => {
  const [fontElement, setFontElement] = useState(null);
  const [previousFontName, setPreviousFontName] = useState("");
  const [fontName, setFontName] = useState("");

  const createElement = (url) => {
    var element = document.createElement("link");
    element.rel = "stylesheet";
    element.href = url;
    return element;
  };

  const fontUrlFromName = (name = "") =>
    name &&
    `https://fonts.googleapis.com/css2?family=${name
      .trim()
      .replace(" ", "+")}:wght@400;500;600;700&display=swap`;

  useEffect(() => {
    if (fontName.length > 0 && fontName !== previousFontName) {
      if (fontElement) document.head.removeChild(fontElement);
      let newFontElement = createElement(fontUrlFromName(fontName));
      document.head.appendChild(newFontElement);
      setFontElement(newFontElement);
      setPreviousFontName(fontName);
    }
  }, [fontName]);

  return { setFontName };
};

export default useFontLoader;

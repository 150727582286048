import { OptionButton as CafOptionButton } from "@combateafraude/react";
import classNames from "classnames";

export const OptionButton = ({ children, className, selected, ...rest }) => (
  <CafOptionButton
    className={classNames(
      "h-20 flex items-center !border !rounded-xl !text-primary",
      `${selected ? "!border-primary !bg-primary-light" : "!border-gray-100"}`,
      className
    )}
    {...rest}
  >
    {children}
  </CafOptionButton>
);

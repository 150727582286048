import {
  CardIllustration,
  CardText,
  CardTitle,
} from "../../../components/Card";

import NotFoundIllustration from "../../../assets/images/illustrations/not-found.png";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center h-full justify-center text-center">
      <CardIllustration src={NotFoundIllustration} alt="Erro 404" />
      <CardTitle text="Página não encontrada" />
      <CardText text="Essa URL não existe ou já expirou." />
    </div>
  );
};

export default NotFound;

import { Icon } from "@combateafraude/react";
import { useEffect } from "react";
import { CardText, CardTitle } from "../../../components/Card";

const InstructionItem = ({ icon, text }) => (
  <div className="flex items-center mb-5">
    <div className="relative p-3 mr-5 rounded-full overflow-hidden text-primary">
      <span className="block absolute -m-3 w-full h-full bg-primary opacity-10 z-0" />
      <Icon className="relative z-20" icon={icon} size="lg" />
    </div>
    <CardText className="font-bold !mb-0" small text={text} />
  </div>
);

const Instructions = ({ isVisible, setCanGoForward }) => {
  // Allows to move forward
  useEffect(() => {
    if (isVisible) {
      setCanGoForward(true);
    }
  }, [isVisible, setCanGoForward]);

  return (
    <div className="flex flex-col">
      <CardTitle text="Prepare sua verificação" />
      <CardText className="!mb-6" text="Siga atentamente as dicas abaixo." />
      <InstructionItem icon="sidebar" text="Tire a capinha do seu documento." />
      <InstructionItem
        icon="sun"
        text="Para garantir a qualidade procure um lugar iluminado."
      />
      <InstructionItem
        icon="faceID"
        text="A captura é automática, é só posicionar e aguardar."
      />
      <InstructionItem icon="glasses" text="Não use óculos ou bonés." />
    </div>
  );
};

export default Instructions;

import { useContext, useEffect, useState } from "react";
import useDD from "../../../hooks/useDD";
import useAPI from "../../../hooks/useAPI";
import { OnboardingDataContext } from "../../../context/OnboardingData";
import useLoader from "../../../hooks/useLoader";
import moment from "moment";
import { dateFormat } from "../../../utils";
import { CardText } from "../../../components/Card";

const DD = ({
  currentStep,
  goTo,
  isVisible,
  setCustomGoBackward,
  setCanGoForward,
}) => {
  const {
    setDDFrontCaptureUrl,
    setDDBackCaptureUrl,
    setPersonName,
    setPersonCpf,
    setPersonBirthDate,
    setPersonMotherName,
  } = useContext(OnboardingDataContext);

  const {
    open: DDOpen,
    close: DDClose,
    startFrontCapture: DDStartFrontCapture,
    startBackCapture: DDStartBackCapture,
    DDIsOpen,
    openingError: DDOpeningError,
    DDFrontPreviewImg,
    DDBackPreviewImg,
    DDCurrentDocumentSide,
    setDDCurrentDocumentSide,
    DDDocumentType,
  } = useDD();
  const [startLoader, stopLoader] = useLoader();
  const { uploadImage, getOcrData } = useAPI();

  const [containerNode, setContainerNode] = useState(null);
  const [previousFrontPreviewImg, setPreviousFrontPreviewImg] = useState(null);
  const [previousBackPreviewImg, setPreviousBackPreviewImg] = useState(null);
  const [customGoBackwardWasSet, setCustomGoBackwardWasSet] = useState(false);

  // Data with post to get OCR data
  const ocrPost = {
    type: DDDocumentType,
    images: {
      front: "",
      back: "",
    },
  };

  useEffect(() => {
    if (isVisible) {
      DDOpen(containerNode);
    }
  }, [isVisible]);

  useEffect(() => {
    if (
      !isVisible &&
      DDIsOpen &&
      currentStep.name !== "DD" &&
      currentStep.name !== "DD_PREVIEW"
    ) {
      DDClose();
    }
  }, [isVisible, DDIsOpen, containerNode, currentStep]);

  useEffect(() => {
    if (isVisible && !DDDocumentType) {
      stopLoader();
      goTo("DOCUMENT_TYPE");
    }
  }, [isVisible, DDDocumentType]);

  useEffect(() => {
    if (isVisible) {
      if (!DDIsOpen && !DDOpeningError) startLoader();
      else stopLoader();
    }
  }, [isVisible, DDIsOpen, DDOpeningError]);

  // Only allows to move forward when there is a preview image for the current document side
  useEffect(() => {
    if (isVisible) {
      if (DDCurrentDocumentSide === "front") {
        DDStartFrontCapture(containerNode);
        setCanGoForward(!!DDFrontPreviewImg);
      } else if (DDCurrentDocumentSide === "back") {
        DDStartBackCapture(containerNode);
        setCanGoForward(!!DDBackPreviewImg);
      }
    }
  }, [isVisible, DDCurrentDocumentSide]);

  useEffect(() => {
    if (isVisible && DDCurrentDocumentSide === "back") {
      if (!customGoBackwardWasSet) {
        setCustomGoBackward(() => () => {
          setDDCurrentDocumentSide("front");
          goTo("DD_PREVIEW");
        });
        setCustomGoBackwardWasSet(true);
      }
    }
  }, [isVisible, DDCurrentDocumentSide]);

  useEffect(() => {
    if (!isVisible) {
      setCustomGoBackwardWasSet(false);
    }
  }, [isVisible]);

  // Goes to the preview automatically when there is a new image
  useEffect(() => {
    if (isVisible) {
      if (DDCurrentDocumentSide === "front") {
        if (
          DDFrontPreviewImg &&
          DDFrontPreviewImg !== previousFrontPreviewImg
        ) {
          setPreviousFrontPreviewImg(DDFrontPreviewImg);
          stepHandler();
          goTo("DD_PREVIEW");
        }
      } else if (DDCurrentDocumentSide === "back") {
        if (DDBackPreviewImg && DDBackPreviewImg !== previousBackPreviewImg) {
          setPreviousBackPreviewImg(DDBackPreviewImg);
          stepHandler();
          goTo("DD_PREVIEW");
        }
      }
    }
  }, [isVisible, DDCurrentDocumentSide, DDFrontPreviewImg, DDBackPreviewImg]);

  const stepHandler = async () => {
    if (DDFrontPreviewImg) {
      const resFrontUpload = await uploadImage(
        DDFrontPreviewImg,
        "DDFrontCapture.jpg"
      );
      if (resFrontUpload && resFrontUpload.getUrl) {
        ocrPost.images.front = resFrontUpload.getUrl;
        setDDFrontCaptureUrl(resFrontUpload.getUrl);
      }
    }

    if (DDBackPreviewImg) {
      const resBackUpload = await uploadImage(
        DDBackPreviewImg,
        "DDBackCapture.jpg"
      );
      if (resBackUpload && resBackUpload.getUrl) {
        ocrPost.images.back = resBackUpload.getUrl;
        setDDBackCaptureUrl(resBackUpload.getUrl);
      }
    }

    if (DDFrontPreviewImg && DDBackPreviewImg) {
      const ocrData = await getOcrData(ocrPost);
      console.log("[DD.jsx] ocrData", ocrData);

      if (ocrData && ocrData.retrievedOcr) {
        setPersonName(ocrData.retrievedOcr.name);
        setPersonCpf(ocrData.retrievedOcr.cpf);
        setPersonMotherName(ocrData.retrievedOcr.motherName);
        if (ocrData.retrievedOcr.birthDate)
          setPersonBirthDate(
            moment(ocrData.retrievedOcr.birthDate, dateFormat)
          );
      }
    }
  };

  return (
    <div
      ref={(node) => setContainerNode(node)}
      className="h-full w-full flex items-center justify-center"
    >
      {DDOpeningError && (
        <CardText
          className="mx-10"
          text="Ocorreu um erro ao abrir o Detector de Documentos. Por favor, refaça o Onboarding. Caso o problema persistir, entre em contato com o suporte."
        />
      )}
    </div>
  );
};

export default DD;

import { createContext, useState } from "react";

export const OnboardingDataContext = createContext({
  // OCR
  personName: "",
  personCpf: "",
  personBirthDate: "",
  personMotherName: "",
  // DispatchAddress
  addressState: null,
  addressStateUf: "",
  addressCity: null,
  addressZipCode: "",
  addressStreet: "",
  addressNeighborhood: "",
  addressNumber: "",
  addressComplement: "",
  // SDK
  DDFrontCaptureUrl: "",
  DDBackCaptureUrl: "",
  PFLCaptureUrl: "",
  // ProofOfResidence
  proofOfResidenceUrl: "",
});

export const OnboardingDataProvider = ({ children }) => {
  // OCR
  const [personName, setPersonName] = useState("");
  const [personCpf, setPersonCpf] = useState("");
  const [personBirthDate, setPersonBirthDate] = useState("");
  const [personMotherName, setPersonMotherName] = useState("");
  // DispatchAddress
  const [addressState, setAddressState] = useState(null);
  const [addressStateUf, setAddressStateUf] = useState("");
  const [addressCity, setAddressCity] = useState(null);
  const [addressZipCode, setAddressZipCode] = useState("");
  const [addressStreet, setAddressStreet] = useState("");
  const [addressNeighborhood, setAddressNeighborhood] = useState("");
  const [addressNumber, setAddressNumber] = useState("");
  const [addressComplement, setAddressComplement] = useState("");
  // SDK
  const [DDFrontCaptureUrl, setDDFrontCaptureUrl] = useState("");
  const [DDBackCaptureUrl, setDDBackCaptureUrl] = useState("");
  const [PFLCaptureUrl, setPFLCaptureUrl] = useState("");
  // ProofOfResidence
  const [proofOfResidenceUrl, setProofOfResidenceUrl] = useState("");

  return (
    <OnboardingDataContext.Provider
      value={{
        // OCR
        personName,
        setPersonName,
        personCpf,
        setPersonCpf,
        personBirthDate,
        setPersonBirthDate,
        personMotherName,
        setPersonMotherName,
        // DispatchAddress
        addressState,
        setAddressState,
        addressStateUf,
        setAddressStateUf,
        addressCity,
        setAddressCity,
        addressZipCode,
        setAddressZipCode,
        addressStreet,
        setAddressStreet,
        addressNeighborhood,
        setAddressNeighborhood,
        addressNumber,
        setAddressNumber,
        addressComplement,
        setAddressComplement,
        // SDK
        DDFrontCaptureUrl,
        setDDFrontCaptureUrl,
        DDBackCaptureUrl,
        setDDBackCaptureUrl,
        PFLCaptureUrl,
        setPFLCaptureUrl,
        // ProofOfResidence
        proofOfResidenceUrl,
        setProofOfResidenceUrl,
      }}
    >
      {children}
    </OnboardingDataContext.Provider>
  );
};

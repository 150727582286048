import { Form, Input, Checkbox } from "antd";
import { useEffect, useState } from "react";
import { hexRegex, isMobile } from "../utils";

const { TextArea } = Input;

/** Used only in dev environment to play with the clientOptions */
const DevOptions = ({ clientOptions, setClientOptions }) => {
  const [showDevOptions, setShowDevOptions] = useState(!isMobile());

  const [unparsedSteps, setUnparsedSteps] = useState("");
  const [validSteps, setValidSteps] = useState(false);

  const [primaryColor, setPrimaryColor] = useState("#666");
  const [validPrimaryColor, setValidPrimaryColor] = useState(false);

  const [secondaryColor, setSecondaryColor] = useState("#666");
  const [validSecondaryColor, setValidSecondaryColor] = useState(false);

  const [illustrationsColor, setIllustrationsColor] = useState("#666");
  const [validIllustrationsColor, setValidIllustrationsColor] = useState(false);

  useEffect(() => {
    if (clientOptions?.steps && clientOptions.steps !== unparsedSteps)
      setUnparsedSteps(JSON.stringify(clientOptions.steps));
    if (clientOptions?.colors) {
      if (clientOptions.colors.primary !== primaryColor)
        setPrimaryColor(clientOptions.colors.primary);
      if (clientOptions.colors.secondary !== secondaryColor)
        setSecondaryColor(clientOptions.colors.secondary);
      if (clientOptions.colors.illustrations !== illustrationsColor)
        setIllustrationsColor(clientOptions.colors.illustrations);
    }
  }, [clientOptions]);

  useEffect(() => {
    try {
      let parsedSteps = JSON.parse(unparsedSteps);
      setClientOptions({
        ...clientOptions,
        steps: parsedSteps,
      });
      setValidSteps(true);
    } catch (err) {
      setValidSteps(false);
    }
  }, [unparsedSteps]);

  useEffect(() => {
    if (hexRegex.test(primaryColor)) {
      setClientOptions({
        ...clientOptions,
        colors: {
          ...clientOptions.colors,
          primary: primaryColor,
        },
      });
      setValidPrimaryColor(true);
    } else {
      setValidPrimaryColor(false);
    }
  }, [primaryColor]);

  useEffect(() => {
    if (hexRegex.test(secondaryColor)) {
      setClientOptions({
        ...clientOptions,
        colors: {
          ...clientOptions.colors,
          secondary: secondaryColor,
        },
      });
      setValidSecondaryColor(true);
    } else {
      setValidSecondaryColor(false);
    }
  }, [secondaryColor]);

  useEffect(() => {
    if (hexRegex.test(illustrationsColor)) {
      setClientOptions({
        ...clientOptions,
        colors: {
          ...clientOptions.colors,
          illustrations: illustrationsColor,
        },
      });
      setValidIllustrationsColor(true);
    } else {
      setValidIllustrationsColor(false);
    }
  }, [illustrationsColor]);

  return (
    <>
      <Checkbox
        checked={showDevOptions}
        onChange={(e) => setShowDevOptions(e.target.checked)}
        className="fixed top-0 z-50"
      >
        showDevOptions
      </Checkbox>
      {showDevOptions && (
        <div className="fixed top-0 z-40 p-10 opacity-80">
          <Form size="small">
            <Form.Item label="background">
              <Input
                value={clientOptions?.background}
                onChange={(e) =>
                  setClientOptions({
                    ...clientOptions,
                    background: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item label="logo">
              <Input
                value={clientOptions?.logo}
                onChange={(e) =>
                  setClientOptions({
                    ...clientOptions,
                    logo: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item label="font">
              <Input
                value={clientOptions?.font}
                onChange={(e) =>
                  setClientOptions({
                    ...clientOptions,
                    font: e.target.value,
                  })
                }
              />
            </Form.Item>
            <Form.Item label="colors.primary">
              <Input
                className={
                  validPrimaryColor ? "!border-green-500" : "!border-red-500"
                }
                value={primaryColor}
                onChange={(e) => setPrimaryColor(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="colors.secondary">
              <Input
                className={
                  validSecondaryColor ? "!border-green-500" : "!border-red-500"
                }
                value={secondaryColor}
                onChange={(e) => setSecondaryColor(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="colors.illustrations">
              <Input
                className={
                  validIllustrationsColor
                    ? "!border-green-500"
                    : "!border-red-500"
                }
                value={illustrationsColor}
                onChange={(e) => setIllustrationsColor(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="documentTypes">
              <Input
                value={clientOptions?.documentTypes}
                onChange={(e) =>
                  setClientOptions({
                    ...clientOptions,
                    documentTypes: e.target.value.split(","),
                  })
                }
              />
            </Form.Item>
            <Form.Item label="mobileOnly">
              <Checkbox
                checked={clientOptions?.mobileOnly}
                onChange={(e) =>
                  setClientOptions({
                    ...clientOptions,
                    mobileOnly: e.target.checked,
                  })
                }
              />
            </Form.Item>
            <Form.Item label="steps">
              <TextArea
                className={validSteps ? "!border-green-500" : "!border-red-500"}
                rows={10}
                value={unparsedSteps}
                onChange={(e) => setUnparsedSteps(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="footer.background">
              <Input
                value={clientOptions?.footer?.background}
                onChange={(e) =>
                  setClientOptions({
                    ...clientOptions,
                    footer: {
                      ...clientOptions.footer,
                      background: e.target.value,
                    },
                  })
                }
              />
            </Form.Item>
            <Form.Item label="footer.logo">
              <Input
                value={clientOptions?.footer?.logo}
                onChange={(e) =>
                  setClientOptions({
                    ...clientOptions,
                    footer: {
                      ...clientOptions.footer,
                      logo: e.target.value,
                    },
                  })
                }
              />
            </Form.Item>
            <Form.Item label="footer.text">
              <Input
                value={clientOptions?.footer?.text}
                onChange={(e) =>
                  setClientOptions({
                    ...clientOptions,
                    footer: {
                      ...clientOptions.footer,
                      text: e.target.value,
                    },
                  })
                }
              />
            </Form.Item>
          </Form>
        </div>
      )}
    </>
  );
};

export default DevOptions;

import { createContext, useState } from "react";

export const SDKContext = createContext({
  DD: null,
  setDD: () => {},
  PFL: null,
  setPFL: () => {},
});

export const SDKProvider = ({ children }) => {
  const [DD, setDD] = useState(null);
  const [DDIsOpen, setDDIsOpen] = useState(false);
  const [DDFrontPreviewImg, setDDFrontPreviewImg] = useState(null);
  const [DDBackPreviewImg, setDDBackPreviewImg] = useState(null);
  const [DDCurrentDocumentSide, setDDCurrentDocumentSide] = useState("front");
  const [DDDocumentType, setDDDocumentType] = useState("");

  const [PFL, setPFL] = useState(null);
  const [PFLIsOpen, setPFLIsOpen] = useState(false);
  const [PFLPreviewImg, setPFLPreviewImg] = useState(null);

  return (
    <SDKContext.Provider
      value={{
        DD,
        setDD,
        DDIsOpen,
        setDDIsOpen,
        DDFrontPreviewImg,
        setDDFrontPreviewImg,
        DDBackPreviewImg,
        setDDBackPreviewImg,
        DDCurrentDocumentSide,
        setDDCurrentDocumentSide,
        DDDocumentType,
        setDDDocumentType,

        PFL,
        setPFL,
        PFLIsOpen,
        setPFLIsOpen,
        PFLPreviewImg,
        setPFLPreviewImg,
      }}
    >
      {children}
    </SDKContext.Provider>
  );
};

import { useEffect, useState } from "react";
import { shadeColor } from "../utils";
import useFontLoader from "./useFontLoader";

const useTheme = () => {
  const { setFontName } = useFontLoader();

  const [colors, setColors] = useState(null);
  const [previousColors, setPreviousColors] = useState(null);

  const [font, setFont] = useState(null);
  const [previousFont, setPreviousFont] = useState(null);

  const [favicon, setFavicon] = useState(null);
  const [previousFavicon, setPreviousFavicon] = useState(null);

  const root = document.documentElement;

  useEffect(() => {
    if (colors) {
      let colorsVars = {};
      if (colors.primary) {
        colorsVars["@primary-color"] = colors.primary;
        root.style.setProperty("--color-primary", colors.primary);
        root.style.setProperty(
          "--color-primary-light",
          shadeColor(colors.primary, 0.9)
        );
      }
      if (colors.secondary) {
        colorsVars["@secondary-color"] = colors.secondary;
        root.style.setProperty("--color-secondary", colors.secondary);
      }
      if (colors.illustrations) {
        root.style.setProperty("--color-illustrations", colors.illustrations);
      }
      if (colorsVars && colorsVars !== previousColors) {
        console.log("CHANGING COLORS: ", colorsVars, previousColors);
        window.less?.modifyVars(colorsVars);
        setPreviousColors(colorsVars);
      }
    }
  }, [colors]);

  useEffect(() => {
    if (font && font !== previousFont) {
      console.log("CHANGING FONT: ", font);
      root.style.setProperty("--font-family", font);
      setFontName(font);
      setPreviousFont(font);
    }
  }, [font]);

  useEffect(() => {
    if (favicon && favicon.length && favicon !== previousFavicon) {
      console.log("CHANGING FAVICON: ", favicon);
      var link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = favicon;
      setPreviousFavicon(favicon);
    }
  }, [favicon]);

  return { setColors, setFont, setFavicon };
};

export default useTheme;

import { useContext } from "react";
import { LoaderContext } from "../context/Loader";

const useLoader = () => {
  const { start, stop, isLoading } = useContext(LoaderContext);

  return [start, stop, isLoading];
};

export default useLoader;

import {
  CardIllustration,
  CardText,
  CardTitle,
} from "../../../components/Card";
import { useContext, useEffect } from "react";
import { OnboardingDataContext } from "../../../context/OnboardingData";
import useDD from "../../../hooks/useDD";
import { APIContext } from "../../../context/API";
import { dateFormat } from "../../../utils";

import moment from "moment";

import AllRightIllustration from "../../../assets/images/illustrations/all-right.png";
import NotFoundIllustration from "../../../assets/images/illustrations/not-found.png";

const Done = ({ isVisible, setCanGoForward, setHideNavigationBar }) => {
  const {
    // OCR
    personName,
    personCpf,
    personBirthDate,
    personMotherName,
    // DispatchAddress
    // addressState,
    addressStateUf,
    addressCity,
    addressZipCode,
    addressStreet,
    addressNeighborhood,
    addressNumber,
    addressComplement,
    // SDK
    DDFrontCaptureUrl,
    DDBackCaptureUrl,
    PFLCaptureUrl,
    // ProofOfResidence
    proofOfResidenceUrl,
  } = useContext(OnboardingDataContext);
  const { sendFinalPost, finalPostSuccess, finalPostError } = useContext(
    APIContext
  );

  const { DDDocumentType } = useDD();

  const finalPost = {
    files: [],
    attributes: {},
  };

  // Hide the navigation bar
  useEffect(() => {
    if (isVisible) {
      setHideNavigationBar(true);
      // OCR
      if (personName) {
        finalPost.attributes.name = personName;
        finalPost.attributes.cpf = personCpf?.replace(".", "").replace("-", "");
        finalPost.attributes.birthDate =
          personBirthDate && moment(personBirthDate).format(dateFormat);
        finalPost.attributes.motherName = personMotherName;
      }
      // DispatchAddress
      if (addressStateUf) {
        finalPost.attributes.address = {
          state: addressStateUf,
          city: addressCity,
          zipCode: addressZipCode?.replace(".", "").replace("-", ""),
          street: addressStreet,
          neighborhood: addressNeighborhood,
          number: addressNumber,
          complement: addressComplement,
        };
      }
      // SDK
      if (DDFrontCaptureUrl && DDBackCaptureUrl) {
        finalPost.files.push({
          type: `${DDDocumentType}_FRONT`,
          data: DDFrontCaptureUrl,
        });
        finalPost.files.push({
          type: `${DDDocumentType}_BACK`,
          data: DDBackCaptureUrl,
        });
      }
      if (PFLCaptureUrl) {
        finalPost.files.push({
          type: "SELFIE",
          data: PFLCaptureUrl,
        });
      }
      // ProofOfResidence
      if (proofOfResidenceUrl) {
        finalPost.files.push({
          type: "RESIDENCE_PROOF",
          data: proofOfResidenceUrl,
        });
      }
      console.log("ONBOARDING DATA:", finalPost);
      sendFinalPost(finalPost);
    }
  }, [isVisible, setCanGoForward]);

  useEffect(() => {
    if (finalPostSuccess || finalPostError) {
      window.parent.postMessage(
        { code: "ONBOARDING_FINISHED", response: { ...finalPost } },
        "*"
      );
    }
  }, [finalPostSuccess, finalPostError]);

  return (
    <div className="flex flex-col items-center h-full justify-center text-center">
      {(finalPostSuccess || finalPostError) && (
        <>
          <CardIllustration
            src={finalPostSuccess ? AllRightIllustration : NotFoundIllustration}
            alt="Onboarding finalizado"
          />
          <CardTitle
            text={finalPostSuccess ? "Tudo certo" : "Ocorreu um erro"}
          />
          <CardText
            text={
              finalPostSuccess
                ? "Sua documentação foi enviada para análise."
                : "Por favor, refaça o Onboarding. Caso o problema persistir, entre em contato com o suporte."
            }
          />
        </>
      )}
    </div>
  );
};

export default Done;

import { MaskedInput } from "@combateafraude/react";
import { DatePicker, Form, Input } from "antd";
import { useContext, useEffect } from "react";
import { CardText, CardTitle } from "../../../components/Card";
import { OnboardingDataContext } from "../../../context/OnboardingData";
import { alphabeticRegex, cpfRegex, dateFormat } from "../../../utils";

const DataConfirmation = ({ isVisible, setCanGoForward }) => {
  const {
    personName,
    setPersonName,
    personCpf,
    setPersonCpf,
    personBirthDate,
    setPersonBirthDate,
    personMotherName,
    setPersonMotherName,
  } = useContext(OnboardingDataContext);

  const validatePersonName = (value) => {
    if (alphabeticRegex.test(value)) {
      setPersonName(value.toUpperCase());
    }
  };

  const validatePersonMotherName = (value) => {
    if (alphabeticRegex.test(value)) {
      setPersonMotherName(value.toUpperCase());
    }
  };

  // Only allows to move forward when all required fields are filled
  useEffect(() => {
    if (isVisible) {
      setCanGoForward(
        personName &&
          personMotherName &&
          personBirthDate &&
          personCpf.match(cpfRegex)
      );
    }
  }, [
    isVisible,
    setCanGoForward,
    personName,
    personMotherName,
    personBirthDate,
    personCpf,
  ]);

  return (
    <div className="flex flex-col">
      <CardTitle text="Confirme seus dados" />
      <CardText
        className="!mb-6"
        text="Para prosseguir confirme se seus dados estão corretos."
      />
      <CardText className="!mb-6 font-bold" text="Dados cadastrais" />
      <Form layout="vertical">
        <Form.Item label="Nome completo">
          <Input
            value={personName}
            onChange={(e) => validatePersonName(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="CPF">
          <MaskedInput
            mask="111.111.111-11"
            value={personCpf}
            onChange={(e) => setPersonCpf(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Data de nascimento">
          <DatePicker
            className="w-full !rounded-lg"
            format={dateFormat}
            value={personBirthDate}
            onChange={(e) => setPersonBirthDate(e)}
          />
        </Form.Item>
        <Form.Item label="Nome da mãe">
          <Input
            value={personMotherName}
            onChange={(e) => validatePersonMotherName(e.target.value)}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default DataConfirmation;

import { useCallback, useContext, useEffect, useState } from "react";
import { SDKContext } from "../context/SDK";

// Importando desse jeito estava impedindo a compilação do projeto, verificar quando possível
// import * as PassiveFaceLiveness from "../assets/js/caf-sdks/passive-face-liveness.min.js";

const usePFL = () => {
  const {
    PFL,
    setPFL,
    PFLIsOpen,
    setPFLIsOpen,
    PFLPreviewImg,
    setPFLPreviewImg,
  } = useContext(SDKContext);

  const [isOpening, setIsOpening] = useState(false);
  const [openingError, setOpeningError] = useState(false);

  const initializePFL = useCallback(() => {
    if (PFL) return;

    const options = {
      client: "onboarding",
      hideTitle: false,
      hideCloseButton: true,
      maskType: "default",
      preview: true,
      // trackingId: this.analyticsTrackingId,
    };

    try {
      const sdk = new window.PassiveFaceLiveness.initialize(
        process.env.REACT_APP_COMBATEAFRAUDE_MOBILE_API_TOKEN,
        options
      );

      sdk.on("ready", async (e) => setPFL(sdk));
    } catch (err) {
      console.log("Erro ao inicializar SDK PassiveFaceLiveness: ", err);
    }
  }, [PFL]);

  useEffect(() => {
    initializePFL();
  }, []);

  let openAttempts = 0;

  const open = (containerNode) => {
    if (PFLIsOpen) {
      console.log("O PFL já está aberto.");
    } else if (!isOpening) {
      setIsOpening(true);
      setOpeningError(false);
      PFL.open({
        parentContainer: containerNode,
      })
        .then(() => {
          PFL.startCapture();
          openAttempts = 0;
          setPFLIsOpen(true);
          setIsOpening(false);
          setOpeningError(false);
        })
        .catch((err) => {
          console.log("PFL OPEN ERROR: ", err);
          if (openAttempts >= 2) {
            console.log(
              "O PFL já tentou abrir 3 vezes sem sucesso, abortando tentativas."
            );
            setPFLIsOpen(false);
            setIsOpening(false);
            setOpeningError(true);
            openAttempts = 0;
          } else {
            openAttempts++;
            console.log("Tentando abrir o PFL novamente...");
            setTimeout(() => open(containerNode), 1000);
          }
        });

      PFL.on("capture_finish", (data) => {
        setPFLPreviewImg(data?.detail?.imagePath);
      });

      PFL.on("finished", (e) => {
        // setPFLPreviewImg(e?.detail?.imageUrl);
        setPFLIsOpen(false);
      });

      PFL.on("closed", (e) => {
        setPFLIsOpen(false);
      });
    }
  };

  const close = () => {
    if (PFLIsOpen) {
      PFL.close();
      setPFLIsOpen(false);
    }
  };

  const startCapture = (containerNode) => {
    if (!PFLIsOpen) {
      open(containerNode);
    } else if (!isOpening) {
      console.log("PFL STARTING CAPTURE");
      PFL.startCapture();
    }
  };

  return {
    PFL,
    open,
    close,
    isOpening,
    openingError,
    startCapture,
    PFLIsOpen,
    PFLPreviewImg,
  };
};

export default usePFL;

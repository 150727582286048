import { useContext, useEffect, useState } from "react";
import Onboarding from "./pages/Onboarding";
import { APIContext } from "./context/API";
import { isMobile } from "./utils";

import CafLogoFull from "./assets/images/caf-logo.svg";
import CafBackground from "./assets/images/caf-background.png";
import { OnlyMobile } from "./pages/OnlyMobile";
import { CardLayout } from "./components/Card";
import { NotFound, ProfileApproved } from "./pages/Onboarding/common";
import { ModalsContext } from "./context/Modals";
import { LoaderContext } from "./context/Loader";
import useTheme from "./hooks/useTheme";

function App() {
  const {
    clientOptions,
    hasFetched,
    isTokenValid,
    onboardingStatus,
  } = useContext(APIContext);
  const { openModals } = useContext(ModalsContext);
  const { Loader } = useContext(LoaderContext);

  const { setColors, setFont, setFavicon } = useTheme();

  const [logoSrc, setLogoSrc] = useState("");
  const [backgroundSrc, setBackgroundSrc] = useState("");

  useEffect(() => {
    if (clientOptions) {
      let logo =
        clientOptions.logo === "loading"
          ? ""
          : clientOptions.logo || CafLogoFull;
      setLogoSrc(logo);
      let background =
        clientOptions.background === "loading"
          ? ""
          : clientOptions.background || CafBackground;
      setBackgroundSrc(background);
      clientOptions.colors && setColors(clientOptions.colors);
      clientOptions.font && setFont(clientOptions.font);
      clientOptions.favicon && setFavicon(clientOptions.favicon);
    }
  }, [clientOptions]);

  return (
    <>
      {clientOptions &&
        (clientOptions.mobileOnly && !isMobile() ? (
          <OnlyMobile />
        ) : (
          <div
            className="w-screen h-full fixed bg-cover bg-center sm:overflow-y-auto overflow-x-hidden"
            style={{
              backgroundImage: backgroundSrc
                ? "url(" + backgroundSrc + ")"
                : "",
              backgroundColor: backgroundSrc?.startsWith("#")
                ? backgroundSrc
                : "",
            }}
          >
            <div className="relative h-full mx-auto flex flex-col items-center pt-2 xs:pt-4 sm:pt-6 xs:px-3 z-20">
              {logoSrc ? (
                <img
                  src={logoSrc}
                  className="my-2 xs:my-4 sm:my-10 h-10 xs:h-12 sm:h-16 px-10 max-w-full xs:max-w-xs xs:px-0 sm:max-w-sm object-scale-down"
                  alt="Logo"
                />
              ) : (
                <span className="block my-2 xs:my-4 sm:my-10 h-10 xs:h-12 sm:h-16"></span>
              )}
              <CardLayout>
                {Loader()}
                {openModals?.map((modal) => modal.component)}
                {hasFetched &&
                  (isTokenValid ? (
                    onboardingStatus === "PENDING" ? (
                      <Onboarding />
                    ) : onboardingStatus === "COMPLETED" ? (
                      <ProfileApproved />
                    ) : (
                      <NotFound />
                    )
                  ) : (
                    <NotFound />
                  ))}
              </CardLayout>
            </div>
            {clientOptions.footer && (
              <div className="absolute bottom-0 w-full min-h-16 h-auto flex items-center justify-center z-10 invisible sm:visible">
                {clientOptions.footer.background && (
                  <img
                    className="absolute bottom-0 z-0 object-fill"
                    style={{ minWidth: "1920px" }}
                    src={clientOptions.footer.background}
                    alt=""
                  />
                )}
                {clientOptions.footer.logo && (
                  <img
                    className="h-8 mr-4 z-10"
                    src={clientOptions.footer.logo}
                    alt=""
                  />
                )}
                {clientOptions.footer.text && (
                  <span className="font-semibold z-10">
                    {clientOptions.footer.text}
                  </span>
                )}
              </div>
            )}
          </div>
        ))}
    </>
  );
}

export default App;

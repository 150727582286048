import { useState } from "react";

const useAPI = () => {
  const [isFetching, setIsFetching] = useState(false);

  const getAddress = async (zipCode) => {
    if (zipCode) {
      setIsFetching(true);
      return await fetch(`https://cep.combateafraude.com/v1/cep/${zipCode}`)
        .then((res) => res.json())
        .then(
          (result) => {
            console.log("GET_ADDRESS RESULT: ", result);
            setIsFetching(false);
            return result;
          },
          (error) => {
            console.log("GET_ADDRESS ERROR: ", error);
            setIsFetching(false);
            return null;
          }
        );
    }
  };

  const getDocumentsSignedUrl = async (data = null) => {
    return await fetch(
      `${
        process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API_OLD
      }upload?nocache=${Date.now()}`,
      {
        method: "POST",
        body: data,
      }
    );
  };

  const uploadDocumentTempImage = async (url, file) => {
    return await fetch(url, {
      method: "PUT",
      body: file,
    });
  };

  const getSignedFileUrl = async (file) => {
    if (file) {
      try {
        setIsFetching(true);

        const signedUrl = await (await getDocumentsSignedUrl())?.json();

        if (!signedUrl || !signedUrl.uploadUrl) {
          console.log("Houve um erro a buscar a URL para envio dos arquivos.");
          return null;
        }

        console.log("GET SIGNED URL: ", signedUrl);

        return await uploadDocumentTempImage(signedUrl.uploadUrl, file)
          .then((res) => {
            setIsFetching(false);
            return { signedUrl };
          })
          .catch((err) => {
            console.log("GET SIGNED URL ERROR: ", err);
            setIsFetching(false);
            return null;
          });

        // this.data[this.document].uploadUrl = res.uploadUrl;
        // this.data[this.document].getUrl = res.getUrl;
      } catch (error) {
        setIsFetching(false);
        return false;
      }
    }
  };

  const getSignedImageUrl = async (data = null) => {
    return await fetch(
      `https://api.dev.combateafraude.com/image-upload?nocache=${Date.now()}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.error("Error:", error);
        return false;
      });
  };

  const uploadTempImage = async (signedUrl, file) => {
    return await fetch(signedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "image/jpg;",
      },
      body: file,
    })
      .then((res) => res.json())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.error("Error:", error);
        return false;
      });
  };

  const uploadImage = async (image, name) => {
    if (!image) {
      return;
    }

    const file = await fetch(image)
      .then((r) => r.blob())
      .then((blobFile) => new File([blobFile], name, { type: "image/jpg" }));

    const responseUrl = await getSignedImageUrl(null);

    if (!responseUrl || !responseUrl.body) {
      console.log("ERRO AO PEGAR URL ASSINADA");
    }

    await uploadTempImage(responseUrl.body.uploadUrl, file);
    return responseUrl.body;
  };

  const getOcrData = async (data) => {
    return await fetch("https://api.mobile.combateafraude.com/document/ocr", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI1ZmQzYTM2MTg2N2FmMTAwMDdmN2YyN2UifQ.xEucK4VgFvljnHwaMV2Q4u7GWBzPEFIG6NccQniGXMo",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.error("Error:", error);
        return false;
      });
  };

  return {
    isFetching,
    getAddress,
    getSignedFileUrl,
    getSignedImageUrl,
    uploadTempImage,
    uploadImage,
    getOcrData,
  };
};

export default useAPI;

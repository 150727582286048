import { MaskedInput } from "@combateafraude/react";
import { Form, Input, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { CardText, CardTitle } from "../../../components/Card";
import { cepRegex } from "../../../utils";
import useAPI from "../../../hooks/useAPI";
import csc from "country-state-city";
import useLoader from "../../../hooks/useLoader";
import { OnboardingDataContext } from "../../../context/OnboardingData";

const { Option } = Select;

const DispatchAddress = ({ isVisible, setCanGoForward }) => {
  const {
    addressState,
    setAddressState,
    setAddressStateUf,
    addressCity,
    setAddressCity,
    addressZipCode,
    setAddressZipCode,
    addressStreet,
    setAddressStreet,
    addressNeighborhood,
    setAddressNeighborhood,
    addressNumber,
    setAddressNumber,
    addressComplement,
    setAddressComplement,
  } = useContext(OnboardingDataContext);

  const [startLoader, stopLoader] = useLoader();
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [stateCode, setStateCode] = useState("");

  useEffect(() => {
    setStatesList(csc.getStatesOfCountry("BR"));
    setCitiesList(csc.getCitiesOfCountry("BR"));
  }, []);

  // Enables the fields after the zip code fetches once
  const [zipCodeFetched, setZipCodeFetched] = useState(false);
  const [zipCodeFailed, setZipCodeFailed] = useState(false);
  const { getAddress, isFetching } = useAPI();

  const setAndFechZipCode = (newZipCode) => {
    setAddressZipCode(newZipCode);
    if (newZipCode && cepRegex.test(newZipCode)) {
      let cleanZipCode = newZipCode.replace(".", "").replace("-", "");
      startLoader();
      getAddress(cleanZipCode)
        .then((result) => {
          if (result) {
            setAddressState(result.estado_info?.nome);
            setStateCode(
              statesList.find(
                (state) => state.name === result.estado_info?.nome
              )?.isoCode
            );
            setAddressCity(result.cidade);
            setAddressStreet(result.logradouro);
            setAddressNeighborhood(result.bairro);
            setZipCodeFailed(false);
          } else {
            setAddressState(null);
            setAddressCity(null);
            setAddressStreet("");
            setAddressNeighborhood("");
            setZipCodeFailed(true);
          }
          setZipCodeFetched(true);
          stopLoader();
        })
        .catch((err) => {
          setZipCodeFetched(true);
          setZipCodeFailed(true);
          stopLoader();
        });
    }
  };

  useEffect(() => {
    setAddressStateUf(
      addressState
        ? statesList.find((state) => state.name === addressState)?.isoCode
        : ""
    );
    if (zipCodeFetched) {
      setAddressCity(null);
      if (statesList && statesList.length) {
        setStateCode(
          statesList.find((listState) => listState.name === addressState)
            ?.isoCode
        );
      }
    }
  }, [addressState, statesList]);

  // Only allows to move forward when all required fields are filled
  useEffect(() => {
    if (isVisible) {
      setCanGoForward(
        addressState &&
          addressCity &&
          addressZipCode.match(cepRegex) &&
          addressStreet &&
          addressNeighborhood &&
          addressNumber
      );
    }
  }, [
    isVisible,
    setCanGoForward,
    addressState,
    addressCity,
    addressZipCode,
    addressStreet,
    addressNeighborhood,
    addressNumber,
    addressComplement,
  ]);

  return (
    <div className="flex flex-col">
      <CardTitle text="Endereço de envio" />
      <CardText
        className="!mb-6"
        text="Para este endereço iremos enviar seu cartão."
      />
      <Form layout="vertical" requiredMark="optional">
        <Form.Item name="zip-code" label="CEP" required>
          <MaskedInput
            mask="11.111-111"
            value={addressZipCode}
            onChange={(e) => setAndFechZipCode(e.target.value)}
          />
        </Form.Item>
        <CardText
          className={`overflow-y-hidden transition-all ${
            zipCodeFailed ? "max-h-48" : "max-h-0 !m-0"
          }`}
          small
          text="Ocorreu um erro ao buscar o CEP. Por favor, preencha os dados manualmente."
        />
        <Form.Item label="Estado" required>
          <Select
            placeholder={
              !zipCodeFetched || isFetching ? "" : "Selecione seu estado..."
            }
            value={addressState}
            onChange={(newState) => setAddressState(newState)}
            showSearch
            allowClear
            disabled={!zipCodeFetched || isFetching}
          >
            {statesList.map((state) => (
              <Option key={state.name}>{state.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Cidade" required>
          <Select
            placeholder={
              !zipCodeFetched || isFetching ? "" : "Selecione sua cidade..."
            }
            value={addressCity}
            onChange={(newCity) => setAddressCity(newCity)}
            showSearch
            allowClear
            disabled={!zipCodeFetched || isFetching}
          >
            {stateCode &&
              citiesList
                .filter((city) => city.stateCode === stateCode)
                .map((city) => <Option key={city.name}>{city.name}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item label="Rua" required>
          <Input
            value={addressStreet}
            onChange={(e) => setAddressStreet(e.target.value)}
            disabled={!zipCodeFetched || isFetching}
          />
        </Form.Item>
        <Form.Item label="Bairro" required>
          <Input
            value={addressNeighborhood}
            onChange={(e) => setAddressNeighborhood(e.target.value)}
            disabled={!zipCodeFetched || isFetching}
          />
        </Form.Item>
        <Form.Item label="Número" required>
          <Input
            value={addressNumber}
            onChange={(e) => setAddressNumber(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Complemento">
          <Input
            value={addressComplement}
            onChange={(e) => setAddressComplement(e.target.value)}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default DispatchAddress;

import { Card } from "@combateafraude/react";

export const CardLayout = ({ customKey, children, className = "" }) => (
  <Card
    key={customKey}
    className={`relative w-full max-w-main-card sm:max-h-main-card h-full sm:h-full !-mb-2 xs:!mb-3 overflow-hidden ${className}`}
    hoverable={false}
    bodyStyle={{ height: "100%" }}
  >
    <div className="h-full flex flex-col justify-center">{children}</div>
  </Card>
);

export const CardTitle = ({ text, small, className = "" }) => (
  <h1
    className={`font-bold text-gray-800 mb-2 xs:mb-3 ${
      small ? "text-2xl" : "text-2xl xs:text-3xl"
    } ${className}`}
  >
    {text}
  </h1>
);

export const CardText = ({ text, small, className = "" }) => (
  <p
    className={`text-gray-800 whitespace-pre-wrap mb-2 xs:mb-3 ${
      small ? "text-sm" : "text-base xs:text-lg"
    } ${className}`}
  >
    {text}
  </p>
);

export const CardIllustration = ({ src, alt, className = "" }) => (
  <span className={`relative my-6 ${className}`}>
    <span
      className="filter-tint-illustrations absolute inline-block top-0 left-0 w-full h-full z-10"
      style={{
        WebkitMask: `url(${src}) center/contain`,
      }}
    />
    <img className="filter-grayscale" src={src} alt={alt} />
  </span>
);
